import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Container, Divider, Grid, IconButton, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import { Facebook, Google, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { stringToSlug } from 'utils/slugify';
import PageSectionContainer from 'components/page/PageSectionContainer';

import { XIcon } from './components/XIcon';
import { XingIcon } from './components';

const PREFIX = 'Footer';

const classes = {
  center: `${PREFIX}-center`,
};

const Root = styled('footer')({
  [`& .${classes.center}`]: {
    textAlign: 'center',
  },
});

interface Social {
  title: string;
  link: string;
  icon: ReactNode;
}

const slugOptions = { lower: true, strict: true };
const currentYear = new Date().getFullYear();

export const Footer = () => {
  const { t, i18n } = useTranslation();

  const onMobileAndTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const shortcuts = [
    {
      name: t('footer.shortcut.1'),
      path: `/articles/${stringToSlug(t('footer.shortcut.1'), slugOptions)}`,
    },
    {
      name: t('footer.shortcut.lab'),
      path: '/lab',
    },
    {
      name: t('footer.shortcut.2'),
      path: `/articles/${stringToSlug(t('footer.shortcut.2'), slugOptions)}`,
    },
    {
      name: t('footer.shortcut.3'),
      path: `/articles/${stringToSlug(t('footer.shortcut.3'), slugOptions)}`,
    },
    {
      name: t('service-center.software-updates.title'),
      path: '/service-center/updates',
    },
  ];

  const socials: Social[] = [
    {
      title: 'Facebook',
      link: `https://www.facebook.com/${t('footer.social.facebook')}`,
      icon: <Facebook />,
    },
    {
      title: 'Instagram',
      link: `https://www.instagram.com/${t('footer.social.instagram')}`,
      icon: <Instagram />,
    },
    {
      title: 'X (Twitter)',
      link: `https://twitter.com/${t('footer.social.twitter')}`,
      icon: <XIcon />,
    },
    {
      title: 'LinkedIn',
      link: 'https://www.linkedin.com/company/4837120',
      icon: <LinkedIn />,
    },
    {
      title: 'YouTube',
      link: 'https://www.youtube.com/channel/UC9PL_2kIEisstpgJZ2GlRfg',
      icon: <YouTube />,
    },
    {
      title: 'Google review',
      link: 'https://g.page/r/CQBke-lX1nYcEAg/review',
      icon: <Google />,
    },
  ];

  return (
    <Root className="site__footer">
      <Box bgcolor="primary.main">
        <PageSectionContainer sx={{ pb: 0 }}>
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                '& a': {
                  display: 'block',
                  mb: 1.5,
                },
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                {t('footer.shortcut.title')}
              </Typography>

              <ul className="no-list-style">
                {shortcuts.map((article, index) => (
                  <li key={`shortcut-${index}`}>
                    <Link component={RouterLink} to={article.path}>
                      {article.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                '& a': {
                  display: 'block',
                  mb: 1.5,
                },
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                {t('footer.contact.title')}
              </Typography>
              <ul className="no-list-style">
                <li key="shortcut-contact-1">
                  <Link component={RouterLink} to={`/articles/${stringToSlug(t('footer.contact.title'), slugOptions)}`}>
                    {t('footer.contact.link')}
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="middle" sx={{ borderColor: 'primary.dark', my: 1, mx: 0 }} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                justifyContent: onMobileAndTablet ? 'flex-start' : 'flex-end',
                pb: 2,
              }}
            >
              {socials.map((social, index) => (
                <IconButton
                  aria-label={social.title}
                  key={`social-${index}`}
                  color="inherit"
                  size="small"
                  component={Link}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 1,
                  }}
                >
                  {social.icon}
                </IconButton>
              ))}
              {i18n.language === 'de' && (
                <IconButton
                  aria-label="Xing"
                  color="inherit"
                  size="small"
                  component={Link}
                  href="https://www.xing.com/pages/tovergmbh"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 1,
                  }}
                >
                  <XingIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </PageSectionContainer>
      </Box>

      <Container
        maxWidth={false}
        sx={{
          backgroundColor: 'secondary.main',
          color: 'common.white',
          p: '1rem 0 2rem',
        }}
      >
        <Grid
          container
          rowSpacing={2}
          sx={{
            alignItems: 'center',
            pt: '1rem',
            justifyContent: onMobileAndTablet ? 'center' : 'space-between',
          }}
        >
          <Grid item xs={12} sm={10} container rowSpacing={2}>
            <Grid
              item
              container
              sx={{
                justifyContent: onMobileAndTablet ? 'center' : undefined,
                gap: 2,
              }}
            >
              <Grid item>
                <Typography>{t('footer.copyright', { year: currentYear })}</Typography>
              </Grid>
              <Grid item>
                <Link color="inherit" component={RouterLink} to="/privacy">
                  {t('footer.policy')}
                </Link>
              </Grid>
              <Grid item>
                <Link color="inherit" component={RouterLink} to="/eula">
                  {t('footer.eula')}
                </Link>
              </Grid>
              <Grid item>
                <Link color="inherit" component={RouterLink} to="/processing-conditions">
                  {t('footer.processing-conditions')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(onMobileAndTablet && 'mb-2')}>
            <Link color="inherit" component={RouterLink} to="/">
              <img
                style={{ height: '50px', width: 'auto' }}
                alt="Tover 10 Years Anniversary"
                src="/images/Tover-Jubileumlogo-Wit.png"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};
